// TermsOnlyModal.js
import React from 'react';
import './TermsOnlyModal.css'; // Import the new CSS for TermsOnlyModal

function TermsOnlyModal({ onClose }) {
    const handleOverlayClick = (event) => {
        if (event.target.className === 'modal-overlay') {
            onClose();
        }
    };

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-content">
                <h2>Terms and Conditions</h2>
                <p><b>IMPORTANT: Please Read Entirely Before Using This Application</b></p>
                <p>By clicking "I Agree" below, you acknowledge and agree to the following terms:</p>
                <ol>
                    <li>
                        <b>Information Only:</b> This app provides predictions and insights to assist users in analyzing sports betting lines. However, it does not offer any direct betting services or guarantee the accuracy or reliability of predictions.
                    </li>
                    <li>
                        <b>No Liability for User Actions:</b> Any decisions you make based on information provided through this app are solely at your discretion and risk. Neither the app nor any person or entity associated with its creation, operation, or ownership will be liable for any losses, damages, or consequences resulting from the use of this information.
                    </li>
                    <li>
                        <b>Release of Liability:</b> You hereby release and forever discharge the app's developers, owners, affiliates, employees, and contractors (collectively, "Released Parties") from any and all claims, demands, damages, costs, and liabilities arising from or relating to your use of this app, including but not limited to losses related to betting, gambling, or other financial transactions.
                    </li>
                    <li>
                        <b>Information Disclaimer:</b> This app provides information and content as-is, without assurances on completeness, reliability, or suitability for any specific purpose. We encourage you to consider this information carefully and apply your own judgment.
                    </li>
                    <li>
                        <b>Assumption of Risk:</b> By using this app, you acknowledge and accept all risks associated with its use, including the possibility of financial loss.
                    </li>
                </ol>
                <p>If you do not agree to these terms, do not use this service.</p>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
}

export default TermsOnlyModal;
