import React, { useState } from 'react';
import { useAuth } from '@clerk/clerk-react';
import './SubscriptionModal.css';

function SubscriptionModal({ onClose }) {
    const { userId } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [isMonthlyLoading, setIsMonthlyLoading] = useState(false);
    const [isAnnualLoading, setIsAnnualLoading] = useState(false);

    // Function to handle the monthly plan subscription
    const handleMonthlySubscription = async () => {
        setIsLoading(true);
        setIsMonthlyLoading(true); // Specific loading state for Monthly button

        try {
            const response = await fetch('https://subscription-service-dot-barrysnipes.uc.r.appspot.com/create-checkout-session', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ clerkUserId: userId, plan: 'monthly' }),
            });
            const data = await response.json();

            if (data.url) {
                window.location.href = data.url;
            } else {
                console.error('Error: Checkout session URL not found.');
                setIsLoading(false);
                setIsMonthlyLoading(false);
            }
        } catch (error) {
            console.error('Error redirecting to checkout:', error);
            setIsLoading(false);
            setIsMonthlyLoading(false);
        }
    };

    // Function to handle the annual plan subscription
    const handleAnnualSubscription = async () => {
        setIsLoading(true);
        setIsAnnualLoading(true); // Specific loading state for Annual button

        try {
            const response = await fetch('https://subscription-service-dot-barrysnipes.uc.r.appspot.com/create-checkout-session-annual', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ clerkUserId: userId, plan: 'annual' }),
            });
            const data = await response.json();

            if (data.url) {
                window.location.href = data.url;
            } else {
                console.error('Error: Checkout session URL not found.');
                setIsLoading(false);
                setIsAnnualLoading(false);
            }
        } catch (error) {
            console.error('Error redirecting to checkout:', error);
            setIsLoading(false);
            setIsAnnualLoading(false);
        }
    };

    return (
        <div className="subscription-modal-overlay">
            <div className="subscription-modal">
                <button className="close-button" onClick={onClose}>&times;</button>
                <h2>Subscription Pricing</h2>
                <p>Ready to find the winning edge with Barry's insights? Subscribe now to chat and guide your next play!</p>
                <div className="plan-options">
                    <div className="plan-option">
                        <h3>Monthly*</h3>
                        <p>$10.99</p>
                        <p>One month of unlimited access</p>
                        <button 
                            onClick={handleMonthlySubscription}
                            disabled={isLoading && isMonthlyLoading}
                            className="choose-plan-button"
                        >
                            {isMonthlyLoading ? 'Processing...' : 'Choose Plan'}
                        </button>
                    </div>
                    <div className="plan-option">
                        <h3>Annual*</h3>
                        <p>$119.99 </p>
                        <p><span className="discount">1 month free</span></p>
                        <p>One year of unlimited access</p>
                        <button 
                            onClick={handleAnnualSubscription}
                            disabled={isLoading && isAnnualLoading}
                            className="choose-plan-button"
                        >
                            {isAnnualLoading ? 'Processing...' : 'Choose Plan'}
                        </button>
                    </div>
                </div>
                <div className="modal-footer">
                    <p>
                        <i>*Membership renews automatically based on your selected plan (monthly or annually). You may cancel your subscription anytime.</i>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default SubscriptionModal;
