// src/LandingPage.js

import React, { useEffect } from 'react';
import { SignInButton, SignUpButton, useUser } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';
import logo from './SSAILogo3.png';
import SportSnipeAIChat from './images/SportSnipeAIChat.PNG';
import TermsOnlyModal from './TermsOnlyModal';
import SubscriptionModal from './components/SubscriptionModal'; // Import SubscriptionModal
import UserProfile from './UserProfile'; // Import UserProfile component

function LandingPage() {
    const { isLoaded, isSignedIn, user } = useUser();
    const navigate = useNavigate();

    // User Metadata Checks
    const subscriptionActive = user?.unsafeMetadata?.subscriptionActive === true;

    const [isTermsModalOpen, setIsTermsModalOpen] = React.useState(false);
    const [showSubscriptionModal, setShowSubscriptionModal] = React.useState(false); // Add state variable

    const openTermsModal = () => setIsTermsModalOpen(true);
    const closeTermsModal = () => setIsTermsModalOpen(false);

    useEffect(() => {
        if (isLoaded && isSignedIn && subscriptionActive) {
            navigate('/'); // If user is signed in and subscribed, redirect to main app
        }
    }, [isLoaded, isSignedIn, subscriptionActive, navigate]);

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    if (isSignedIn) {
        return (
            <div className="landing-page">
                {/* Top Navigation Bar */}
                <header className="navbar">
                    <div className="container">
                        <div className="navbar-left">
                            <img src={logo} alt="SportSnipeAI" className="logo" />
                        </div>
                        <div className="navbar-right">
                            <UserProfile /> {/* Use the reusable UserProfile component */}
                            {subscriptionActive ? (
                                <button className="nav-button get-started" onClick={() => navigate('/')}>Chat</button>
                            ) : (
                                <button className="nav-button get-started" onClick={() => setShowSubscriptionModal(true)}>Subscribe</button>
                            )}
                        </div>
                    </div>
                </header>

                {/* Hero Section */}
                <section className="hero">
                    <div className="container">
                        <h1 className="hero-title">Find The Edge With Barry Snipes</h1>
                        <p className="hero-subtitle">Smart Bets, Sharp Angles: Your Personal Sports Handicapper</p>
                    </div>
                </section>

                {/* About Section */}
                <section className="barry-about-section">
                    <div className="barry-about-content">
                        <div className="barry-about-header">
                            <h2>Personal Handicapper at Your Fingertips</h2> <br />
                            <p>
                                When it comes to sports betting, winning demands more than just gut feelings or hopeful guesses. It takes insight, discipline, and strategy - that's where Barry Snipes comes in. Barry delivers sharp insights with a focused approach, drawing on years of experience and a method that's grounded in careful analysis and machine learning.
                            </p><br />
                            <p>
                                SportsSnipeAI doesn't promise a win every time - because no one can. But what it does offer is a structured, data-driven approach that gives you a real edge over gut instinct. Barry's dedicated to diving deep into every angle, so you can feel confident you're making informed bets.
                            </p><br />
                            <p>
                                Barry Snipes isn't just about giving you picks - he's here to help you understand why those picks make sense. If you're looking to bet smarter and more consistently, Barry's got you covered. No hype, just a seasoned approach to the game. Welcome to sports handicapping, the Barry way.
                            </p>
                        </div>
                    </div>
                    <div className="barry-about-image">
                        <img src={SportSnipeAIChat} alt="Barry Info Section" />
                    </div>
                </section>

                {/* Features Section */}
                <section className="features">
                    <div className="container">
                        <h2>Discover the Features</h2>
                        <div className="feature-list">
                            <div className="feature-item">
                                <h3>Advanced Analytics</h3>
                                <p>Leverage cutting-edge AI to analyze sports data and uncover hidden insights.</p>
                            </div>
                            <div className="feature-item">
                                <h3>Real-Time Updates</h3>
                                <p>Stay ahead with live odds updates and predictions for ongoing games.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Footer Section */}
                <footer className="landing-footer">
                    <div className="container">
                        <p>&copy; {new Date().getFullYear()} SportSnipeAI LLC. All rights reserved.</p>
                        <p>
                            By using this service, you agree to our <button className="terms-link" onClick={openTermsModal}>Terms & Conditions</button>.
                        </p>
                    </div>
                </footer>

                {/* TermsOnlyModal */}
                {isTermsModalOpen && <TermsOnlyModal onClose={closeTermsModal} />}

                {/* SubscriptionModal */}
                {showSubscriptionModal && (
                    <SubscriptionModal
                        user={user}
                        onClose={() => setShowSubscriptionModal(false)}
                    />
                )}
            </div>
        );
    }

    // If Not Signed-In: Render Existing LandingPage Content
    return (
        <div className="landing-page">
            {/* Top Navigation Bar */}
            <header className="navbar">
                <div className="container">
                    <div className="navbar-left">
                        <img src={logo} alt="SportSnipeAI" className="logo" />
                    </div>
                    <div className="navbar-right">
                        <SignInButton mode="modal">
                            <button className="nav-button log-in">Log In</button>
                        </SignInButton>
                        <SignUpButton mode="modal">
                            <button className="nav-button get-started">Get Started</button>
                        </SignUpButton>
                    </div>
                </div>
            </header>

            {/* Hero Section */}
            <section className="hero">
                <div className="container">
                    <h1 className="hero-title">Find The Edge With Barry Snipes</h1>
                    <p className="hero-subtitle">Smart Bets, Sharp Angles: Your Personal Sports Handicapper</p>
                </div>
            </section>

            {/* About Section */}
            <section className="barry-about-section">
                <div className="barry-about-content">
                    <div className="barry-about-header">
                        <h2>Personal Handicapper at Your Fingertips</h2> <br />
                        <p>
                            When it comes to sports betting, winning demands more than just gut feelings or hopeful guesses. It takes insight, discipline, and strategy - that's where Barry Snipes comes in. Barry delivers sharp insights with a focused approach, drawing on years of experience and a method that's grounded in careful analysis and machine learning.
                        </p><br />
                        <p>
                            SportsSnipeAI doesn't promise a win every time - because no one can. But what it does offer is a structured, data-driven approach that gives you a real edge over gut instinct. Barry's dedicated to diving deep into every angle, so you can feel confident you're making informed bets.
                        </p><br />
                        <p>
                            Barry Snipes isn't just about giving you picks; he's here to help you understand why those picks make sense. If you're looking to bet smarter and more consistently, Barry's got you covered - no hype, just a seasoned approach to the game. Welcome to sports handicapping, the Barry way.
                        </p>
                    </div>
                </div>
                <div className="barry-about-image">
                    <img src={SportSnipeAIChat} alt="Barry Info Section" />
                </div>
            </section>

            {/* Features Section */}
            <section className="features">
                <div className="container">
                    <h2>Discover the Features</h2>
                    <div className="feature-list">
                        <div className="feature-item">
                            <h3>Advanced Analytics</h3>
                            <p>Leverage cutting-edge AI to analyze sports data and uncover hidden insights.</p>
                        </div>
                        <div className="feature-item">
                            <h3>Real-Time Updates</h3>
                            <p>Stay ahead with live odds updates and predictions for ongoing games.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Footer Section */}
            <footer className="landing-footer">
                <div className="container">
                    <p>&copy; {new Date().getFullYear()} SportSnipeAI LLC. All rights reserved.</p>
                    <p>
                        By using this service, you agree to our <button className="terms-link" onClick={openTermsModal}>Terms & Conditions</button>.
                    </p>
                </div>
            </footer>

            {/* TermsOnlyModal */}
            {isTermsModalOpen && <TermsOnlyModal onClose={closeTermsModal} />}
        </div>
    );
}

export default LandingPage;
