// src/App.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { FaSpinner } from 'react-icons/fa';
import './App.css';
import logo from './SSAILogo3.png';
import {
    SignedIn,
    useUser,
    useAuth,
} from '@clerk/clerk-react';
import LandingPage from './LandingPage';
import TermsModal from './TermsModal';
import SubscriptionModal from './components/SubscriptionModal';
import nflLogo from './images/nflLogo.png';
import nbaLogo from './images/nbaLogo.png';
import mlbLogo from './images/mlbLogo.png';
import nhlLogo from './images/nhlLogo.png';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import UserProfile from './UserProfile'; // Import UserProfile component

function App() {
    return (
        <Routes>
            <Route path="/landing" element={<LandingPageProtected />} />
            <Route path="/" element={<MainApp />} />
            <Route path="*" element={<Navigate to="/landing" replace />} />
        </Routes>
    );
}

function LandingPageProtected() {
    const { isLoaded, isSignedIn, user } = useUser();
    const navigate = useNavigate();

    const subscriptionActive = user?.unsafeMetadata?.subscriptionActive === true;

    useEffect(() => {
        if (isLoaded && isSignedIn && subscriptionActive) {
            navigate('/');
        }
    }, [isLoaded, isSignedIn, subscriptionActive, navigate]);

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return <LandingPage />;
}

function MainApp() {
    const navigate = useNavigate();

    const [userInput, setUserInput] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [threadId, setThreadId] = useState(null);
    const chatBoxRef = useRef(null);
    const textareaRef = useRef(null);
    const lastMessageRef = useRef(null);
    const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
    const [selectedSport, setSelectedSport] = useState("NFL");
    const [showTiles, setShowTiles] = useState(true);
    const [showTilesModal, setShowTilesModal] = useState(false);
    const [spinnerMessage, setSpinnerMessage] = useState('Digging into the details... results are coming!');
    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

    const { isLoaded, isSignedIn, user } = useUser();
    const { getToken } = useAuth();

    const hasAcceptedTerms = user?.unsafeMetadata?.hasAcceptedTerms === true;
    const subscriptionActive = user?.unsafeMetadata?.subscriptionActive === true;

    useEffect(() => {
        const initializeSubscription = async () => {
            if (isLoaded && isSignedIn && user) {
                if (typeof user.unsafeMetadata.subscriptionActive === 'undefined') {
                    try {
                        await user.update({
                            unsafeMetadata: {
                                ...user.unsafeMetadata,
                                subscriptionActive: false,
                            },
                        });
                    } catch (error) {
                        console.error('Error initializing subscription status:', error);
                    }
                }
            }
        };
        initializeSubscription();
    }, [isLoaded, isSignedIn, user]);

    useEffect(() => {
        if (isLoaded && isSignedIn) {
            if (!hasAcceptedTerms) {
                setIsTermsModalOpen(true);
            }
            if (!subscriptionActive) {
                setShowSubscriptionModal(true);
            }
        }
    }, [isLoaded, isSignedIn, hasAcceptedTerms, subscriptionActive]);

    const handleSend = async () => {
        if (userInput.trim()) {
            setShowWelcomeMessage(false);
            setShowTiles(false);
            setShowTilesModal(false);
            const newHistory = [...chatHistory, { user: userInput }];
            setChatHistory(newHistory);
            setIsProcessing(true);
            setUserInput('');

            try {
                const authToken = await getToken();

                const response = await axios.post(
                    'https://backend-api-dot-barrysnipes.uc.r.appspot.com/interact',
                    {
                        input: userInput,
                        thread_id: threadId,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                    }
                );

                const botResponse = response.data.response;
                setThreadId(response.data.thread_id);

                setChatHistory((prevHistory) => [...prevHistory, { bot: botResponse }]);
            } catch (error) {
                console.error('Error communicating with backend:', error);
                setChatHistory((prevHistory) => [
                    ...prevHistory,
                    {
                        bot: 'Stepped away from the lab for a bit. Please check back in an hour or so.',
                    },
                ]);
            } finally {
                setIsProcessing(false);
            }
        }
    };

    const handleAgreeToTerms = async () => {
        try {
            await user.update({
                unsafeMetadata: {
                    ...user.unsafeMetadata,
                    hasAcceptedTerms: true,
                },
            });
            setIsTermsModalOpen(false);
        } catch (error) {
            console.error('Error updating user metadata:', error);
        }
    };

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatHistory]);

    useEffect(() => {
        if (userInput === '' && textareaRef.current) {
            textareaRef.current.style.height = 'auto';
        }
    }, [userInput]);

    useEffect(() => {
        let timer;
        if (isProcessing) {
            timer = setTimeout(() => {
                setSpinnerMessage('Still digging in. Give me a few moments to lock in.');
            }, 15000);
        } else {
            setSpinnerMessage('Digging into the details... results are coming!');
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [isProcessing]);

    const handleSportChange = (sport) => {
        setSelectedSport(sport);
        setShowTilesModal(false);
    };

    const resetTiles = () => {
        setShowTilesModal(true);
    };

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    if (!isSignedIn) {
        return <Navigate to="/landing" replace />;
    }

    if (!hasAcceptedTerms) {
        return <TermsModal onAgree={handleAgreeToTerms} />;
    }

    return (
        <>
            <div className="main-container">
                <div className="left-panel">
                    <SignedIn>
                        <div className="desktop-user-button">
                            <UserProfile /> {/* Replace with reusable UserProfile component */}
                        </div>
                    </SignedIn>
                    {!showTiles && (
                        <button onClick={resetTiles} className="change-sport-button">
                            <img
                                src={
                                    selectedSport === "NFL"
                                        ? nflLogo
                                        : selectedSport === "NBA"
                                            ? nbaLogo
                                            : selectedSport === "MLB"
                                                ? mlbLogo
                                                : nhlLogo
                                }
                                alt="Selected Sport"
                                className="selected-sport-icon"
                            />
                            Change Sport
                        </button>
                    )}
                </div>

                <div className="chat-container">
                    <header>
                        <SignedIn>
                            <div className="mobile-user-button">
                                <UserProfile /> {/* Replace with reusable UserProfile component */}
                            </div>
                        </SignedIn>
                    </header>

                    <img src={logo} alt="SportSnipeAI" className="logo" />

                    {showTiles && (
                        <div className="sport-tiles">
                            <div className="tile-row">
                                <div onClick={() => handleSportChange("NFL")} className={`tile ${selectedSport === "NFL" ? "active" : ""}`}>
                                    <img src={nflLogo} alt="NFL" />
                                </div>
                                <div className="tile disabled">
                                    <img src={nbaLogo} alt="NBA" />
                                    <div className="overlay">Coming Soon</div>
                                </div>
                            </div>
                            <div className="tile-row">
                                <div className="tile disabled">
                                    <img src={mlbLogo} alt="MLB" />
                                    <div className="overlay">Spring 2025</div>
                                </div>
                                <div className="tile disabled">
                                    <img src={nhlLogo} alt="NHL" />
                                    <div className="overlay">Fall 2025</div>
                                </div>
                            </div>
                        </div>
                    )}

                    {showTilesModal && (
                        <div className="sport-tiles-modal">
                            <div className="tile-row-modal">
                                <div onClick={() => handleSportChange("NFL")} className={`tile ${selectedSport === "NFL" ? "active" : ""}`}>
                                    <img src={nflLogo} alt="NFL" />
                                </div>
                                <div className="tile disabled">
                                    <img src={nbaLogo} alt="NBA" />
                                    <div className="overlay">Coming Soon</div>
                                </div>
                            </div>
                            <div className="tile-row-modal">
                                <div className="tile disabled">
                                    <img src={mlbLogo} alt="MLB" />
                                    <div className="overlay">Spring 2025</div>
                                </div>
                                <div className="tile disabled">
                                    <img src={nhlLogo} alt="NHL" />
                                    <div className="overlay">Fall 2025</div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={`chat-box ${isTermsModalOpen ? 'disabled' : ''}`} ref={chatBoxRef}>
                        {chatHistory.map((chat, index) => (
                            <div
                                key={index}
                                className="message"
                                ref={index === chatHistory.length - 1 ? lastMessageRef : null}
                            >
                                {chat.user && (
                                    <p className="user">
                                        <strong>You:</strong> {chat.user}
                                    </p>
                                )}
                                {chat.bot && (
                                    <div className="bot">
                                        <ReactMarkdown>{chat.bot}</ReactMarkdown>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>

                    {showWelcomeMessage && (
                        <div className="welcome-message">
                            <span className="text">What action are you looking at today?</span>
                            <span className="caret">|</span>
                        </div>
                    )}

                    {isProcessing && (
                        <div className="spinner">
                            <FaSpinner className="spinner-icon" />
                            {spinnerMessage}
                        </div>
                    )}

                    <div className="input-container">
                        <textarea
                            ref={textareaRef}
                            value={userInput}
                            onChange={(e) => setUserInput(e.target.value)}
                            placeholder="Let's find the edge."
                            disabled={isProcessing || isTermsModalOpen}
                            rows={1}
                            onInput={(e) => {
                                e.target.style.height = 'auto';
                                e.target.style.height = `${e.target.scrollHeight}px`;
                            }}
                        />
                        <button onClick={handleSend} disabled={isProcessing || isTermsModalOpen}>
                            Send
                        </button>
                    </div>
                    <div className="footer-text">
                        Provide the games, sports, or props you are targeting for the best results.
                    </div>

                    {isTermsModalOpen && (
                        <TermsModal onAgree={handleAgreeToTerms} />
                    )}

                    {showSubscriptionModal && (
                        <SubscriptionModal
                            user={user}
                            onClose={() => {
                                // Navigate back to the landing page when modal is closed without subscribing
                                navigate('/landing');
                            }}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default App;
